<template>
  <div>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Encrypt</v-card-title>
                    <v-card-text>
                        <v-form ref="encrypt_form" v-model="valid_encrypt">
                            <v-text-field outlined required :rules="rules.required" v-model="crypto.encrypt.Securitykey" label="Security Key" clearable/>
                            <v-text-field outlined required :rules="rules.required" v-model="crypto.encrypt.initVector" label="initVector" clearable/>
                            <v-text-field outlined required :rules="rules.required" v-model="crypto.encrypt.input" label="Encrypt This" :append-icon="valid_encrypt ? 'mdi-server-security' : ''" @click:append="encrypt" clearable/>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Decrypt</v-card-title>
                    <v-card-text>
                        <v-form ref="decrypt_form" v-model="valid_decrypt">
                            <v-text-field outlined required :rules="rules.required" v-model="crypto.decrypt.Securitykey" label="Security Key" clearable/>
                            <v-text-field outlined required :rules="rules.required" v-model="crypto.decrypt.initVector" label="initVector" clearable/>
                            <v-text-field outlined required :rules="rules.required" v-model="crypto.decrypt.encrypted" label="Decrypt This" :append-icon="valid_decrypt ? 'mdi-server-security' : ''" @click:append="decrypt" clearable/>
                        </v-form>                
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>



  </div>
</template>

<script>
import validations from '@/plugins/validations.js'
export default {
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        this.ready = true
    },
    data: function(){
        return {
            ready: false,
            validations: null,
            rules: null,
            valid_encrypt: false,
            valid_decrypt: false,
            crypto: {
                encrypt: {
                    input: null,
                    Securitykey: null,
                    initVector: null
                },
                decrypt: {
                    encrypted: null,
                    Securitykey: null,
                    initVector: null,
                }
            }            
        }
    },
    methods: {
        encrypt: async function(){
            let response = await this.sendRequest('post','/api/helper/crypto/encrypt',this.crypto.encrypt)
            this.crypto.decrypt.encrypted = response.data.encryptedData
            this.crypto.decrypt.initVector = response.data.initVector
            this.crypto.decrypt.Securitykey = response.data.Securitykey
        },
        decrypt: async function(){
            let response = await this.sendRequest('post','/api/helper/crypto/decrypt',this.crypto.decrypt)
            console.log(response)
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        }
    }
}
</script>

<style>

</style>